import React from "react"

/*** COMPONENTS ***/
import Main from "../../components/main"
import SEO from "../../components/seo"
import "../../styles/project.css"
import keyloggerHeader from "../../images/NetworkSequrity.png"

/*** CONSTANTS ***/
import * as ROUTES from "../../constants/routes"

function openGithubKeylogger() {
  window.open(
    "https://github.com/johannagranstroem/TNM031-Network-programming-and-security",
    "_blank"
  )
}

const keyloggerPage = () => (
  <Main>
    <SEO title="Keylogger" />
    <div class="project-container">
      <div class="project-grid-item-11">
        <img alt="Header-Tetris" src={keyloggerHeader} />
        <div class="project-circle">
          <p class="project-header-text-black">KEYLOGGER</p>
          <p class="project-header-text-grey">NETWORK SECURITY</p>
        </div>
      </div>
      <div class="project-grid-item-21">
        <p class="project-bulletpoint-text">Language</p>
        <p class="project-bulletpoint-text"> &#9642; Python</p>
      </div>
      <div class="project-grid-item-22">
        <p class="project-bulletpoint-text">Status</p>
        <p class="project-bulletpoint-text"> &#8226; Completed 2018-10-22</p>
      </div>
      <div class="project-grid-item-31">
        <p class="project-text-body-dark">
          My associate and I developed a keylogger virus that could be spread
          via a link. When a user clicked the link, the keylogger saved all
          keystrokes executed by a user and save them as txt-files. The
          keylogger also took screenshots of the screen every third second.
          <br />
          <br />
          This project was the first project done where I got the insight into
          how network and security are implemented and used in our everyday
          life.
          <br />
          <br />
          You can checkout the project on{" "}
          <button
            class="project-text-link-style"
            onClick={() => openGithubKeylogger()}
          >
            Github
          </button>
          !
          <br />
          <br />
        </p>
      </div>
      <div class="project-grid-item-61">
        <a class="project-text-back-to-projects" href={ROUTES.PROJECTS}>
          &#8592; Back to projects
        </a>
      </div>
    </div>
  </Main>
)

export default keyloggerPage
